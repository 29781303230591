import { useProductLineItem } from '@/modules/cart/hooks/useProductLineItem';

import { CounterButton } from './components/CounterButton';
import { FoldableProductCounterContainer } from './components/FoldableProductCounterContainer';
import { FoldableProductCounterProps } from './types';

export const FoldableProductCounter = (props: FoldableProductCounterProps) => {
  const productLineItem = useProductLineItem(props.sku);
  const quantityInCurrentCart = productLineItem?.quantity ?? 0;
  const isProductAvailable =
    props.availableQuantity > quantityInCurrentCart && props.isPublished;

  return (
    <FoldableProductCounterContainer
      isProductAddedInCart={quantityInCurrentCart > 0}
      isProductAvailable={isProductAvailable}
    >
      <CounterButton {...props} />
    </FoldableProductCounterContainer>
  );
};
