import { useCallback, useEffect, useState } from 'react';

import { DecreaseQuantityButton } from '@/core/ui/components/DecreaseQuantityButton/DecreaseQuantityButton';
import { IncreaseQuantityButton } from '@/core/ui/components/IncreaseQuantityButton/IncreaseQuantityButton';
import { cn } from '@/core/ui/utils';
import { useProductLineItem } from '@/modules/cart/hooks/useProductLineItem';
import { Button } from '@lib/theme/components/Button';
import { ButtonSizeVariant } from '@lib/theme/config/createButtonClasses';
import { useSetTimeout } from '@lib/theme/hooks/useSetTimeout';

import { FoldableProductCounterProps } from '../types';

export const CounterButton = ({
  size,
  setIsCounterFolded,
  isFolded,
  sku,
  isPublished,
  availableQuantity,
  iconSize,
  attributionToken,
}: FoldableProductCounterProps) => {
  const [isMouseOverCounter, setIsMouseOverCounter] = useState(false);

  const foldCounter = useCallback(() => {
    if (isMouseOverCounter) return;
    return setIsCounterFolded(true);
  }, [isMouseOverCounter, setIsCounterFolded]);

  const { resetTimer } = useSetTimeout({
    onTimeout: foldCounter,
    startCountdown: !isFolded,
    timeMs: autoFoldCounterTimeMs,
  });

  useEffect(() => {
    if (isMouseOverCounter) return;

    // reset the timer after mouse leaves the counter
    // otherwise, it will never close
    return resetTimer();
  }, [isMouseOverCounter, resetTimer]);

  const unFoldCounter = useCallback(() => {
    setIsCounterFolded(false);
    resetTimer();
  }, [resetTimer, setIsCounterFolded]);

  const onMouseOver = () => setIsMouseOverCounter(true);
  const onMouseLeave = () => setIsMouseOverCounter(false);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        unFoldCounter();
      }
    },
    [unFoldCounter]
  );

  const productLineItem = useProductLineItem(sku);
  const quantityInCurrentCart = productLineItem?.quantity ?? 0;
  const isProductAvailable =
    availableQuantity > quantityInCurrentCart && isPublished;

  const isInitialButton = quantityInCurrentCart === 0;

  const isFoldedButton = quantityInCurrentCart > 0 && isFolded;

  const buttonSize = buttonSizes[size];

  if (isInitialButton) {
    return (
      <IncreaseQuantityButton
        size={buttonSize}
        sku={sku}
        isProductAvailable={isProductAvailable}
        iconSize={iconSize}
        onClick={unFoldCounter}
        attributionToken={attributionToken}
        color="tertiary/counterNarrow"
      />
    );
  }

  if (isFoldedButton) {
    return (
      <Button
        size={buttonSize}
        color="tertiary/counterNarrow/active"
        round="full"
        width="w-auto"
        padding="none"
        onClick={unFoldCounter}
        testId="product-counter"
      >
        <span className="text-lg">{quantityInCurrentCart}</span>
      </Button>
    );
  }

  return (
    <div
      onClick={unFoldCounter}
      className="flex items-center border-2 border-primary-400 bg-primary-400"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      role="button"
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onFocus={onMouseOver}
    >
      <DecreaseQuantityButton
        sku={sku}
        size={buttonSize}
        iconSize={iconSize}
        onClick={unFoldCounter}
        attributionToken={attributionToken}
      />
      <span
        className={cn('text-white', {
          [cn('mx-3', 'text-lg')]: size === 'md',
          [cn('mx-2', 'text-base')]: size === 'sm' || size === 'xs',
        })}
        data-testid="ProductCounterNarrow__quantity-in-active-cart"
      >
        {quantityInCurrentCart}
      </span>
      <IncreaseQuantityButton
        size={buttonSize}
        sku={sku}
        isProductAvailable={isProductAvailable}
        iconSize={iconSize}
        onClick={unFoldCounter}
        attributionToken={attributionToken}
      />
    </div>
  );
};

const autoFoldCounterTimeMs = 3000;

const buttonSizes: Record<ButtonSizeVariant, ButtonSizeVariant> = {
  xs: 'xs',
  lg: 'lg',
  base: 'base',
  md: 'md',
  '2md': '2md',
  sm: 'sm',
};
