import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

export const FoldableProductCounterContainer = ({
  isProductAvailable,
  isProductAddedInCart,
  children,
}: {
  isProductAvailable: boolean;
  isProductAddedInCart: boolean;
  children: ReactNode;
}) => (
  <div
    className={cn(
      'absolute right-3 top-3.5 z-10 overflow-hidden rounded-full bg-transparent',
      !isProductAddedInCart && isProductAvailable && 'shadow-product-counter'
    )}
  >
    {children}
  </div>
);
